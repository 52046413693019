import React from 'react';
import { FelaCSS, colors } from '@bridebook/ui';
import IconWrap from '@bridebook/ui/src/components/svg/icon-wrap';

interface IProps {
  theme?: 'light' | 'dark';
  width?: number;
  style?: FelaCSS;
}

interface IconTypes {
  iconColor: string;
  width: number;
  height: number;
}

const themeToTextColor = {
  light: colors.white,
  dark: colors.space,
};

const themeToIconColor = {
  light: colors.space,
  dark: colors.white,
};

const BridebookLogoBusiness = ({ theme = 'light', width = 239, style }: IProps) => {
  const viewBoxWidth = 239;
  const viewBoxHeight = 24;

  const textColor = themeToTextColor[theme];
  const iconColor = themeToIconColor[theme];

  const icon = ({ width, height }: IconTypes) => (
    <svg
      width={`${width}px`}
      height={`${height}px`}
      viewBox={`0 0 ${viewBoxWidth} ${viewBoxHeight}`}
      fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.737 17.5278C37.1434 18.1127 36.4494 18.3973 35.6145 18.3973H32.0334V12.4215H35.6145C36.4485 12.4215 37.1417 12.7096 37.7353 13.3006C38.3298 13.8941 38.6266 14.5845 38.6416 15.4099C38.6266 16.2491 38.3307 16.9421 37.737 17.5278ZM32.0334 5.60297H34.6008C35.2789 5.60297 35.8408 5.83484 36.3173 6.31069C36.7964 6.79 37.0289 7.34545 37.0289 8.01078C37.0289 8.67524 36.7973 9.22722 36.3199 9.69702C35.8426 10.1677 35.2798 10.3961 34.6008 10.3961H32.0334V5.60297ZM37.8861 10.9757C38.6682 10.1659 39.0636 9.20985 39.0636 8.12491C39.0636 6.83406 38.6241 5.74566 37.7566 4.88999C36.8891 4.03433 35.8278 3.60001 34.6018 3.60001H31.0058C30.7319 3.60001 30.4853 3.70037 30.295 3.88898C30.1022 4.07845 30 4.3207 30 4.58978V19.4095C30 19.6725 30.0942 19.9104 30.2704 20.0973C30.4553 20.2954 30.7019 20.3993 30.9829 20.3993H35.6155C37.0097 20.3993 38.2146 19.9078 39.1975 18.9388C40.1795 17.9707 40.6771 16.7828 40.6771 15.4098C40.6771 14.3317 40.3486 13.3515 39.7004 12.4958C39.2054 11.8426 38.5968 11.3339 37.8861 10.9757Z"
        fill={textColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M52.2255 10.6675C51.6478 11.2524 50.9705 11.55 50.1541 11.5777H46.5484V5.6028H50.0695C50.9018 5.6028 51.5976 5.88744 52.1965 6.47316C52.7945 7.05974 53.086 7.74409 53.086 8.56773C53.086 9.39484 52.8042 10.0818 52.2255 10.6675ZM55.8748 18.8149C55.7453 18.4325 55.452 18.1954 55.0592 18.1504C54.965 17.9618 54.8408 17.5431 54.8408 16.6364C54.8408 15.535 54.6057 14.6396 54.1406 13.976C53.7936 13.4794 53.3762 13.0572 52.8962 12.7146C53.4519 12.3434 53.9222 11.8641 54.2983 11.2844C54.8258 10.4729 55.0936 9.56622 55.0936 8.59031C55.0936 7.21815 54.6012 6.03113 53.6316 5.06214C52.6584 4.09229 51.4606 3.60001 50.07 3.60001H45.4761L45.4259 3.60347C45.1811 3.63029 44.96 3.74189 44.7883 3.92531C44.6122 4.11219 44.5188 4.34232 44.5188 4.59063V19.4093C44.5188 19.6783 44.6201 19.9206 44.8094 20.1083C45.0014 20.2987 45.2472 20.399 45.5219 20.399C45.7906 20.399 46.0372 20.3021 46.2344 20.1196C46.4396 19.9301 46.5489 19.6844 46.5489 19.4093V13.5581H49.87L49.974 13.5745C50.5605 13.6697 51.1462 13.9189 51.7063 14.3117C52.4646 14.8645 52.8336 15.5359 52.8336 16.3639C52.8336 18.8754 53.4801 20.1498 54.7554 20.1498H54.8021L55.0539 20.1265H55.1614L55.2389 20.0971C55.7656 19.8998 56.0078 19.4387 55.888 18.8659L55.8748 18.8149Z"
        fill={textColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M61.419 3.87342C61.1854 3.69432 60.898 3.60001 60.5852 3.60001C60.2663 3.60001 59.9778 3.69864 59.7523 3.8838C59.5218 4.07415 59.3999 4.31814 59.3999 4.58982V19.4102C59.3999 19.6827 59.5218 19.9276 59.7513 20.1153C59.9768 20.3014 60.2652 20.4 60.5852 20.4C60.899 20.4 61.1875 20.3057 61.419 20.1275C61.6679 19.9362 61.7999 19.6888 61.7999 19.4102V4.58982C61.7999 4.31208 61.6679 4.06463 61.419 3.87342Z"
        fill={textColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M76.9695 12.0114C76.9695 13.7919 76.3477 15.2688 75.068 16.5268C73.7883 17.7856 72.2823 18.3973 70.4644 18.3973H68.4669V5.60297H70.4609C72.2805 5.61768 73.7874 6.23629 75.068 7.49513C76.3477 8.75397 76.9695 10.2317 76.9695 12.0114ZM70.4646 3.60001H67.4366C67.1636 3.60001 66.917 3.70037 66.7241 3.88984C66.5312 4.07845 66.4299 4.3207 66.4299 4.58978V19.4095C66.4299 19.6794 66.5277 19.9174 66.7118 20.0991C66.887 20.2704 67.0984 20.3707 67.3415 20.3967L70.4646 20.3993C72.8126 20.3993 74.8444 19.5756 76.5046 17.9517C78.1648 16.3269 79.0068 14.3317 79.0068 12.0226C79.0068 9.71426 78.1657 7.71222 76.5064 6.07183C74.8453 4.43231 72.8126 3.60001 70.4646 3.60001Z"
        fill={textColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M92.6045 5.60299C92.8753 5.60299 93.1192 5.50263 93.3081 5.31401C93.4989 5.12539 93.5999 4.8788 93.5999 4.60193C93.5999 4.32593 93.4989 4.07934 93.309 3.88986C93.1183 3.70037 92.8744 3.60001 92.6045 3.60001H84.3945C84.1236 3.60001 83.8806 3.70037 83.6899 3.89072C83.5001 4.07934 83.3999 4.3216 83.3999 4.58982V19.4102C83.3999 19.6801 83.4966 19.9189 83.6786 20.0989C83.8502 20.2711 84.06 20.3715 84.2995 20.3974L84.3239 20.4H92.6045C92.8762 20.4 93.1192 20.2996 93.3081 20.111C93.4989 19.9224 93.5999 19.6758 93.5999 19.3989C93.5999 19.1229 93.4989 18.8772 93.309 18.6869C93.1183 18.4974 92.8744 18.3979 92.6045 18.3979H85.4117V13.0127H91.6465C91.9173 13.0127 92.1612 12.9132 92.351 12.7238C92.5409 12.5343 92.6419 12.292 92.6419 12.0229C92.6419 11.7599 92.5452 11.5176 92.3615 11.3221C92.1699 11.1179 91.9234 11.0106 91.6465 11.0106H85.4117V5.60299H92.6045Z"
        fill={textColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M106.068 15.4094C106.053 16.2486 105.757 16.9416 105.164 17.5274C104.57 18.1123 103.876 18.3969 103.041 18.3969H99.46V12.4218H103.041C103.874 12.4218 104.568 12.7091 105.161 13.3C105.757 13.8944 106.053 14.584 106.068 15.4094ZM99.46 10.3964V5.60227H102.027C102.705 5.60227 103.267 5.83415 103.744 6.31088C104.222 6.78933 104.456 7.34566 104.456 8.011C104.456 8.67462 104.223 9.22662 103.747 9.69729C103.269 10.1671 102.706 10.3964 102.027 10.3964H99.46ZM105.312 10.975C106.094 10.1661 106.49 9.20914 106.49 8.12417C106.49 6.83328 106.05 5.74485 105.183 4.89003C104.315 4.03347 103.253 3.60001 102.027 3.60001H98.4318C98.1579 3.60001 97.9113 3.70037 97.7202 3.88898C97.5282 4.0776 97.426 4.31986 97.426 4.5898V19.4099C97.426 19.6729 97.5194 19.9109 97.6955 20.0969C97.8814 20.295 98.128 20.3997 98.4081 20.3997H103.042C104.436 20.3997 105.641 19.9083 106.623 18.9392C107.605 17.9702 108.103 16.7823 108.103 15.4092C108.103 14.3312 107.775 13.3509 107.127 12.4952C106.632 11.8428 106.023 11.3332 105.312 10.975Z"
        fill={textColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M124.565 11.9966C124.551 13.7847 123.941 15.3286 122.75 16.5855C121.553 17.8492 120.157 18.4644 118.479 18.4644C116.801 18.4644 115.401 17.8527 114.195 16.5941C112.999 15.3466 112.393 13.7967 112.393 11.9889C112.393 10.1819 112.999 8.64055 114.193 7.40679C115.4 6.1636 116.805 5.55871 118.491 5.55871C120.176 5.55871 121.574 6.16274 122.764 7.40508C123.945 8.6397 124.551 10.1862 124.565 11.9966ZM118.514 3.54959C116.262 3.54959 114.326 4.38238 112.757 6.02398C111.196 7.657 110.397 9.66701 110.382 12.0026C110.397 14.333 111.196 16.3422 112.757 17.9761C114.326 19.6177 116.262 20.4505 118.514 20.4505C120.765 20.4505 122.694 19.6168 124.249 17.9744C125.793 16.3413 126.583 14.3322 126.6 11.9975C126.583 9.66787 125.793 7.65872 124.249 6.02569C122.694 4.38324 120.765 3.54959 118.514 3.54959Z"
        fill={textColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M143.857 11.9964C143.843 13.7739 143.236 15.3086 142.053 16.558C140.863 17.8142 139.475 18.4257 137.808 18.4257C136.139 18.4257 134.747 17.8176 133.549 16.5665C132.36 15.3264 131.758 13.7858 131.758 11.9888C131.758 10.1926 132.359 8.66044 133.547 7.43404C134.746 6.19827 136.143 5.59699 137.819 5.59699C139.494 5.59699 140.884 6.19742 142.066 7.43234C143.241 8.65959 143.843 10.1968 143.857 11.9964ZM137.842 3.59995C135.604 3.59995 133.679 4.42777 132.119 6.05956C130.567 7.68284 129.773 9.68085 129.759 12.0025C129.773 14.319 130.567 16.3162 132.119 17.9403C133.679 19.5721 135.604 20.3999 137.842 20.3999C140.08 20.3999 141.998 19.5713 143.543 17.9386C145.077 16.3153 145.863 14.319 145.88 11.9974C145.863 9.68085 145.077 7.68369 143.543 6.06127C141.998 4.42862 140.08 3.59995 137.842 3.59995Z"
        fill={textColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M161.789 18.7804L155.823 11.1467L161.607 5.32054C161.811 5.11526 161.919 4.86475 161.919 4.59336C161.919 4.32458 161.82 4.08189 161.63 3.89053C161.441 3.70004 161.2 3.60001 160.932 3.60001C160.675 3.60001 160.433 3.69308 160.233 3.86966L160.222 3.88009L151.397 12.7672V4.61684C151.397 4.32632 151.29 4.07146 151.085 3.87835C150.89 3.69656 150.649 3.60001 150.387 3.60001C150.112 3.60001 149.867 3.70787 149.679 3.91141C149.497 4.10799 149.4 4.35154 149.4 4.61684V19.4067C149.4 19.678 149.501 19.9216 149.688 20.1086C149.876 20.2991 150.118 20.4 150.387 20.4C150.65 20.4 150.892 20.3035 151.087 20.1208C151.29 19.9294 151.397 19.6824 151.397 19.4067V15.6037L154.398 12.5811L160.212 20.0129C160.385 20.2626 160.647 20.4 160.955 20.4C161.228 20.4 161.454 20.3269 161.625 20.1808L161.653 20.1556C162.061 19.7442 162.112 19.2301 161.789 18.7804Z"
        fill={textColor}
      />
      <path
        d="M167.606 20.4C167.379 20.4 167.188 20.3225 167.033 20.1674C166.878 20.0124 166.8 19.8215 166.8 19.5949V8.62297C166.8 8.39635 166.878 8.20551 167.033 8.05046C167.188 7.88348 167.379 7.79999 167.606 7.79999H171.831C173.108 7.79999 174.093 8.0922 174.785 8.67664C175.489 9.26107 175.841 10.0483 175.841 11.0382C175.841 11.73 175.674 12.3085 175.34 12.7736C175.018 13.2269 174.6 13.679 174.087 13.9056C174.803 14.1322 175.388 14.496 175.841 14.997C176.307 15.4979 176.539 16.1301 176.539 16.8934C176.539 17.9907 176.169 18.8495 175.429 19.4697C174.689 20.0899 173.526 20.4 171.938 20.4H167.606ZM171.401 13.203C172.463 13.203 173.209 13.0241 173.639 12.6663C174.069 12.3085 174.284 11.8075 174.284 11.1635C174.284 10.6029 174.051 10.1556 173.585 9.82165C173.132 9.48769 172.505 9.32071 171.706 9.32071H168.429V13.203H171.401ZM171.777 18.8793C172.935 18.8793 173.752 18.7004 174.23 18.3425C174.719 17.9847 174.964 17.4778 174.964 16.8218C174.964 15.4621 173.908 14.7823 171.795 14.7823H168.429V18.8793H171.777Z"
        fill={textColor}
      />
      <path
        d="M181.884 20.3821C181.227 20.3821 180.655 20.227 180.165 19.9169C179.688 19.6068 179.318 19.1834 179.055 18.6467C178.805 18.11 178.679 17.5196 178.679 16.8755V11.6051C178.679 11.3785 178.757 11.1876 178.912 11.0326C179.079 10.8775 179.276 10.8 179.503 10.8C179.73 10.8 179.92 10.8775 180.076 11.0326C180.231 11.1876 180.308 11.3785 180.308 11.6051V16.6966C180.308 18.1517 180.965 18.8793 182.278 18.8793C182.874 18.8793 183.406 18.7242 183.871 18.4141C184.349 18.104 184.796 17.6866 185.214 17.1618V11.6051C185.214 11.3785 185.291 11.1876 185.447 11.0326C185.614 10.8775 185.811 10.8 186.037 10.8C186.264 10.8 186.455 10.8775 186.61 11.0326C186.765 11.1876 186.843 11.3785 186.843 11.6051V19.4697C186.843 19.6963 186.765 19.8931 186.61 20.0601C186.455 20.2151 186.264 20.2926 186.037 20.2926C185.811 20.2926 185.614 20.2151 185.447 20.0601C185.291 19.8931 185.214 19.6963 185.214 19.4697V18.7361C184.76 19.2371 184.271 19.6367 183.746 19.9348C183.221 20.233 182.6 20.3821 181.884 20.3821Z"
        fill={textColor}
      />
      <path
        d="M192.563 20.3024C191.369 20.3024 190.283 20.0341 189.304 19.4974C189.101 19.3542 189 19.1574 189 18.907C189 18.7161 189.066 18.5491 189.197 18.406C189.328 18.2629 189.495 18.1913 189.698 18.1913C189.806 18.1913 189.913 18.2152 190.021 18.2629C190.402 18.4299 190.784 18.5611 191.166 18.6565C191.548 18.7519 191.978 18.7996 192.455 18.7996C193.78 18.7996 194.443 18.3762 194.443 17.5294C194.443 17.255 194.287 17.0463 193.977 16.9032C193.679 16.7601 193.183 16.599 192.491 16.4201C191.787 16.2532 191.202 16.0802 190.737 15.9013C190.283 15.7224 189.889 15.454 189.555 15.0962C189.233 14.7265 189.112 14.2231 189.112 13.6267C189.112 12.7918 189.41 12.112 190.007 11.5872C190.616 11.0624 191.481 10.8 192.603 10.8C193.522 10.8 194.387 10.9491 195.199 11.2473C195.366 11.295 195.491 11.3844 195.575 11.5156C195.67 11.6468 195.718 11.784 195.718 11.9271C195.718 12.1179 195.646 12.2849 195.503 12.4281C195.372 12.5712 195.205 12.6427 195.002 12.6427C194.942 12.6427 194.865 12.6308 194.769 12.607C194.077 12.3923 193.397 12.2849 192.728 12.2849C192.084 12.2849 191.576 12.4042 191.206 12.6427C190.848 12.8694 190.669 13.1675 190.669 13.5373C190.669 13.8951 190.796 14.1778 191.131 14.3448C191.465 14.5118 191.984 14.6728 192.688 14.8279C193.392 15.0068 193.965 15.1857 194.407 15.3646C194.848 15.5316 195.224 15.7999 195.535 16.1697C195.857 16.5394 196.018 17.0344 196.018 17.6546C196.018 18.4179 195.714 19.0501 195.105 19.551C194.508 20.052 193.661 20.3024 192.563 20.3024Z"
        fill={textColor}
      />
      <path
        d="M199.09 9.67609C198.84 9.67609 198.585 9.60696 198.406 9.42805C198.227 9.24914 198.137 9.03445 198.137 8.78398V8.71242C198.137 8.46195 198.227 8.24726 198.406 8.06835C198.585 7.88944 198.8 7.79999 199.05 7.79999H199.158C199.408 7.79999 199.623 7.88944 199.802 8.06835C199.981 8.24726 200.071 8.46195 200.071 8.71242V8.78398C200.071 9.03445 199.981 9.24914 199.802 9.42805C199.623 9.60696 199.448 9.67609 199.198 9.67609H199.09ZM199.086 20.3821C198.859 20.3821 198.668 20.3046 198.513 20.1495C198.358 19.9945 198.28 19.8036 198.28 19.577L198.32 11.623C198.32 11.3963 198.398 11.2055 198.553 11.0505C198.708 10.8835 198.899 10.8 199.126 10.8C199.365 10.8 199.562 10.8775 199.717 11.0326C199.872 11.1876 199.95 11.3844 199.95 11.623L199.91 19.577C199.91 19.8036 199.826 19.9945 199.659 20.1495C199.504 20.3046 199.313 20.3821 199.086 20.3821Z"
        fill={textColor}
      />
      <path
        d="M203.544 20.3821C203.317 20.3821 203.127 20.3046 202.971 20.1495C202.816 19.9945 202.739 19.8036 202.739 19.577L202.779 11.7124C202.779 11.4858 202.856 11.295 203.012 11.1399C203.167 10.9729 203.358 10.8894 203.584 10.8894C203.811 10.8894 204.002 10.9729 204.157 11.1399C204.324 11.295 204.408 11.4858 204.408 11.7124V12.4459C204.862 11.945 205.351 11.5454 205.876 11.2473C206.401 10.9491 207.022 10.8 207.738 10.8C208.394 10.8 208.961 10.955 209.439 11.2652C209.928 11.5753 210.298 11.9987 210.549 12.5354C210.811 13.0721 210.902 13.6828 210.902 14.3269V19.577C210.902 19.8036 210.819 19.9945 210.652 20.1495C210.497 20.3046 210.306 20.3821 210.079 20.3821C209.852 20.3821 209.661 20.3046 209.506 20.1495C209.351 19.9945 209.273 19.8036 209.273 19.577V14.5058C209.273 13.0507 208.657 12.3028 207.344 12.3028C206.747 12.3028 206.21 12.4579 205.733 12.768C205.267 13.0781 204.826 13.4955 204.408 14.0203L204.368 19.577C204.368 19.8036 204.284 19.9945 204.117 20.1495C203.962 20.3046 203.771 20.3821 203.544 20.3821Z"
        fill={textColor}
      />
      <path
        d="M217.73 20.3821C216.274 20.3821 215.134 19.9706 214.311 19.1476C213.499 18.3127 213.093 17.1439 213.093 15.641C213.093 14.7942 213.289 13.9926 213.599 13.277C213.909 12.5614 214.387 11.9829 215.031 11.5416C215.676 11.1003 216.469 10.8796 217.412 10.8796C218.296 10.8796 219.059 11.0884 219.704 11.5058C220.348 11.9113 220.838 12.46 221.172 13.1518C221.506 13.8316 221.633 14.5974 221.633 15.4085C221.633 15.6351 221.556 15.8259 221.4 15.981C221.257 16.136 221.06 16.2135 220.81 16.2135H214.74C214.836 17.0484 215.146 17.6985 215.671 18.1636C216.197 18.6288 216.931 18.8614 217.873 18.8614C218.375 18.8614 218.81 18.8137 219.18 18.7183C219.562 18.6228 219.932 18.4857 220.29 18.3068C220.386 18.2591 220.493 18.2352 220.613 18.2352C220.804 18.2352 220.971 18.3008 221.114 18.432C221.257 18.5632 221.329 18.7302 221.329 18.9329C221.329 19.1953 221.174 19.416 220.863 19.5949C220.398 19.8573 219.938 20.0541 219.485 20.1853C219.031 20.3165 218.446 20.3821 217.73 20.3821ZM220.022 14.8896C219.986 14.3052 219.871 13.7958 219.597 13.4022C219.334 13.0086 219.006 12.7224 218.612 12.5435C218.218 12.3527 217.818 12.2572 217.412 12.2572C217.007 12.2572 216.607 12.3527 216.213 12.5435C215.819 12.7224 215.491 13.0086 215.228 13.4022C214.966 13.7958 214.776 14.3052 214.74 14.8896H220.022Z"
        fill={textColor}
      />
      <path
        d="M226.743 20.3821C225.549 20.3821 224.463 20.1137 223.484 19.577C223.282 19.4339 223.18 19.2371 223.18 18.9866C223.18 18.7958 223.246 18.6288 223.377 18.4857C223.508 18.3426 223.675 18.271 223.878 18.271C223.986 18.271 224.093 18.2948 224.201 18.3425C224.583 18.5095 224.964 18.6407 225.346 18.7361C225.728 18.8316 226.158 18.8793 226.635 18.8793C227.96 18.8793 228.623 18.4559 228.623 17.609C228.623 17.3347 228.467 17.126 228.157 16.9828C227.859 16.8397 227.363 16.6787 226.671 16.4998C225.967 16.3328 225.382 16.1599 224.917 15.981C224.463 15.8021 224.069 15.5337 223.735 15.1759C223.413 14.8061 223.292 14.3028 223.292 13.7064C223.292 12.8715 223.59 12.1916 224.187 11.6668C224.796 11.142 225.661 10.8796 226.783 10.8796C227.702 10.8796 228.567 11.0287 229.379 11.3269C229.546 11.3746 229.671 11.4641 229.755 11.5953C229.85 11.7265 229.898 11.8636 229.898 12.0068C229.898 12.1976 229.827 12.3646 229.683 12.5077C229.552 12.6508 229.385 12.7224 229.182 12.7224C229.122 12.7224 229.045 12.7105 228.949 12.6866C228.257 12.4719 227.577 12.3646 226.908 12.3646C226.264 12.3646 225.757 12.4839 225.387 12.7224C225.028 12.949 224.849 13.2472 224.849 13.6169C224.849 13.9748 224.976 14.2575 225.311 14.4245C225.645 14.5914 226.164 14.7525 226.868 14.9075C227.572 15.0864 228.145 15.2653 228.587 15.4442C229.028 15.6112 229.404 15.8796 229.715 16.2493C230.037 16.6191 230.198 17.114 230.198 17.7343C230.198 18.4976 229.894 19.1297 229.285 19.6307C228.688 20.1316 227.841 20.3821 226.743 20.3821Z"
        fill={textColor}
      />
      <path
        d="M235.345 20.3821C234.151 20.3821 233.065 20.1137 232.086 19.577C231.883 19.4339 231.782 19.2371 231.782 18.9866C231.782 18.7958 231.848 18.6288 231.979 18.4857C232.11 18.3426 232.277 18.271 232.48 18.271C232.588 18.271 232.695 18.2948 232.802 18.3425C233.184 18.5095 233.566 18.6407 233.948 18.7361C234.33 18.8316 234.76 18.8793 235.237 18.8793C236.562 18.8793 237.225 18.4559 237.225 17.609C237.225 17.3347 237.069 17.126 236.759 16.9828C236.461 16.8397 235.965 16.6787 235.273 16.4998C234.569 16.3328 233.984 16.1599 233.519 15.981C233.065 15.8021 232.671 15.5337 232.337 15.1759C232.015 14.8061 231.894 14.3028 231.894 13.7064C231.894 12.8715 232.192 12.1916 232.789 11.6668C233.398 11.142 234.263 10.8796 235.385 10.8796C236.304 10.8796 237.169 11.0287 237.981 11.3269C238.148 11.3746 238.273 11.4641 238.357 11.5953C238.452 11.7265 238.5 11.8636 238.5 12.0068C238.5 12.1976 238.428 12.3646 238.285 12.5077C238.154 12.6508 237.987 12.7224 237.784 12.7224C237.724 12.7224 237.647 12.7105 237.551 12.6866C236.859 12.4719 236.179 12.3646 235.51 12.3646C234.866 12.3646 234.358 12.4839 233.988 12.7224C233.63 12.949 233.451 13.2472 233.451 13.6169C233.451 13.9748 233.578 14.2575 233.912 14.4245C234.247 14.5914 234.766 14.7525 235.47 14.9075C236.174 15.0864 236.747 15.2653 237.189 15.4442C237.63 15.6112 238.006 15.8796 238.317 16.2493C238.639 16.6191 238.8 17.114 238.8 17.7343C238.8 18.4976 238.496 19.1297 237.887 19.6307C237.29 20.1316 236.443 20.3821 235.345 20.3821Z"
        fill={textColor}
      />
      <path
        d="M0 7.68C0 4.99175 0 3.64762 0.523169 2.62085C0.983361 1.71767 1.71767 0.983361 2.62085 0.523169C3.64762 0 4.99175 0 7.68 0H16.32C19.0082 0 20.3524 0 21.3792 0.523169C22.2823 0.983361 23.0166 1.71767 23.4768 2.62085C24 3.64762 24 4.99175 24 7.68V16.32C24 19.0083 24 20.3524 23.4768 21.3792C23.0166 22.2823 22.2823 23.0166 21.3792 23.4768C20.3524 24 19.0082 24 16.32 24H7.68C4.99175 24 3.64762 24 2.62085 23.4768C1.71767 23.0166 0.983361 22.2823 0.523169 21.3792C0 20.3524 0 19.0083 0 16.32V7.68Z"
        fill={textColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.1359 15.1512C16.5329 17.3344 14.2985 17.4107 14.2985 17.4107C14.2985 17.4107 14.2951 17.4062 14.2905 17.3988C13.9898 17.4649 13.6817 17.4882 13.3765 17.4671L6.94606 17.4677C6.94263 17.4677 6.9392 17.4666 6.93577 17.466H6.53508V17.4649V17.0849C6.53393 17.0747 6.53165 17.0639 6.53165 17.0531V10.6243C6.51164 10.3195 6.53451 10.0131 6.60024 9.71351L6.5991 9.71294C6.5991 9.71294 6.67512 7.47849 8.85919 6.87588C11.0427 6.27327 12.1419 7.8134 12.1419 7.8134C12.1419 7.8134 13.0518 8.65808 12.933 10.4762C12.9278 10.7034 12.9004 10.9307 12.8472 11.1534C13.1359 11.0845 13.432 11.0571 13.7263 11.0691C15.4125 11.0235 16.1979 11.8688 16.1979 11.8688C16.1979 11.8688 17.7389 12.9681 17.1359 15.1512ZM17.7768 10.8967C16.9034 10.023 15.7539 9.52347 14.5227 9.47848C14.5164 9.31672 14.501 9.15496 14.4792 8.99434C14.4512 8.78815 14.4112 8.58425 14.3569 8.38319C14.2889 8.13257 14.198 7.88879 14.0911 7.65299C13.851 7.12442 13.5184 6.63915 13.1045 6.22506C12.8353 5.95566 12.5398 5.72327 12.2237 5.52734C11.4623 5.05402 10.5827 4.79999 9.66354 4.79999C8.54206 4.79999 7.4789 5.17762 6.6175 5.87136C6.48032 5.98129 6.34828 6.09919 6.22196 6.22563C5.85328 6.59415 5.55033 7.0162 5.32055 7.47243C5.16679 7.77716 5.04618 8.09669 4.9593 8.42647C4.82955 8.92143 4.77753 9.43918 4.80897 9.96148L4.8084 17.7043C4.8084 17.7054 4.80897 17.7066 4.80897 17.7071V19.1886H4.8084H6.25054C6.26597 19.1892 6.28083 19.1909 6.29683 19.1909H14.0402C14.1397 19.1971 14.2397 19.2 14.3392 19.2C15.639 19.2 16.8605 18.6948 17.7768 17.7783C19.6745 15.8811 19.6745 12.794 17.7768 10.8967Z"
        fill={iconColor}
      />
    </svg>
  );

  return <IconWrap {...{ icon, width, viewBoxWidth, viewBoxHeight, style }} />;
};

export default BridebookLogoBusiness;
